import axios from 'axios'

const service = axios.create({
    // baseURL: '/api',
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 5000 * 60
})

// request interceptor
service.interceptors.request.use(
    config => {
      config.headers['Mch-No'] = 356843
      return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            console.log('\n请求出错：' + '%c' + response.request.responseURL + '\n', 'background: yellow;', res.message, '\n\n')
            window.alert(res.message)
            return Promise.reject(new Error(res.message || 'Error'))
        }else {
            // return res.data || res
            return res
        }
    },
    error => {
        return Promise.reject(error)
    }
)
export default service