<template>
    <div class="container">
        <div class="header">
            <img src="../../assets/image/logo.png" @click="toIndex">
            <div>
                <ul>
                    <li v-for="(item,index) in navList" :key="index" :class="item.active?'active':''" @click="changeNav(index,item.routerPath)">
                        <div v-if="item.routerPath != '#'" class="title">{{ item.title }}</div>
                        <div v-else class="title yd">
                            <span :class="isSelectSubMenu?'sub_active':''">{{ item.title }}</span>
                            <div class="menu">
                                <div class="menu_item" ref="menuItem" v-for="(item2,index2) in menuList" :key="index2" :class="item2.active?'active2':''" @click="changeMenu(item2.brand_id)">
                                    <div class="text">{{ item2.title }}</div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import { getBrandList } from '@/api/brand'
export default {
    data() {
        return {
            navList: [
                { title: '首页',active: false,routerPath: '/' },
                { title: '运东品牌',active: false,routerPath: '#' },
                { title: '商务合作',active: false,routerPath: '/cooperation' },
                { title: '我要找房',active: false,routerPath: '/lookHouse' },
                { title: '新闻资讯',active: false,routerPath: '/news' },
                { title: '关于运东',active: false,routerPath: '/aboutUs' },
            ],
            menuList: [],
            menuHeight: '0px'
        }
    },
    watch: {
        $route(n) {
            window.localStorage.setItem('routePath',n.path)
            this.defaultRoute()
        }
    },
    mounted() {
        this.initData()

        window.onresize = () => {
            return (() => {
                this.$nextTick(() => {
                    let height = this.$refs.menuItem[0].offsetHeight
                    this.menuHeight = (this.menuList.length * height) + 'px'
                    this.defaultRoute()
                })
            })()
        }
    },
    methods: {
        async initData() {
            const res = await getBrandList()
            if(res.code == 200) {
                res.data.forEach(item => {
                    item['active'] = false
                })
                this.menuList = res.data
                this.$nextTick(() => {
                    let height = this.$refs.menuItem[0].offsetHeight
                    this.menuHeight = (this.menuList.length * height) + 'px'
                    this.defaultRoute()
                })
            }
        },
        defaultRoute() {
            let selectPath = window.localStorage.getItem('routePath')
            let isFind = false
            if(selectPath == '/newsDetail') {
                selectPath = '/news'
            }
            this.navList.forEach(item => {
                if(item.routerPath == selectPath) {
                    item.active = true
                    isFind = true
                }else {
                    item.active = false
                }
            })
            //改变菜单状态
            this.menuList.forEach(item => {
                item.active = false
            })
            if(!isFind) {
                this.menuList.forEach(item => {
                    if(item.brand_id == this.$route.query.id) {
                        item.active = true
                    }else {
                        item.active = false
                    }
                })
            }
        },
        changeNav(i,path) {
            if(path != '#') {
                this.navList.forEach((item,index) => {
                    if(index == i) {
                        item.active = true
                    }else {
                        item.active = false
                    }
                })
                this.$router.push(path)
            }
        },
        changeMenu(id) {
            this.menuList.forEach(item => {
                if(id == item.brand_id) {
                    item.active = true
                }else {
                    item.active = false
                }
            })
            this.$router.push(`/brandDetail?id=${id}`)
        },
        toIndex() {
            this.$router.push('/')
        }
    },
    computed: {
        isSelectSubMenu() {
            return this.menuList.some(item => {
                return item.active
            })
        }
    }
}
</script>
<style scoped lang="scss">
$width: 1600px;
.active {
    color: #FFA71D!important;
    position: relative;
    &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0px;
        left: 50%;
        width: 30px;
        height: 5px;
        background: #FFA71D;
        transform: translateX(-50%);
    }
}
.sub_active {
    color: #FFA71D!important;
}
.container {
    width: 100vw;
    height: 70px;
    display: flex;
    justify-content: center;
    // position: fixed;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;
    .header {
        width: $width;
        height: 70px;
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 50px;
        box-sizing: border-box;
        img {
            width: 172px;
            height: 46px;
            cursor: pointer;
        }
        ul {
            display: flex;
            column-gap: 50px;
            height: 70px;
            align-items: center;
            li {
                cursor: pointer;
                font-size: 16px;
                color: #666666;
                transition: all 0.3s ease;
                -webkit-user-select: none; /* Safari */
                -moz-user-select: none; /* Firefox */
                -ms-user-select: none; /* IE 10+/Edge */
                user-select: none;
                height: 70px;
                display: flex;
                align-items: center;
                &:hover {
                    color: #FFA71D;
                }
                .title {
                    height: 100%;
                    line-height: 70px;
                }
            }
        }
    }
}
.yd {
    position: relative;
    &:hover {
        .menu {
            height: v-bind(menuHeight);
        }
    }
    .menu {
        position: absolute;
        top: 60px;
        left: -37px;
        background: #fff;
        width: 140px;
        height: 0;
        border-radius: 4px;
        color: #666;
        font-size: 14px;
        overflow: hidden;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
        &_item {
            width: 140px;
            height: 32px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .text {
                height: 100%;
                width: 100%;
                line-height: 32px;
                padding: 0 10px;
                box-sizing: border-box;
                transition: all 0.3s ease;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        .active2 {
            color: #fff;
            background: #FFA71D;
        }
    }
}
</style>