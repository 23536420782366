import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from '@/utils/ismobile.js'
Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'index', component: () => import('../views')},
    { path: '/brandDetail', name: 'brandDetail', component: () => import('../views/brand/brandDetail')},
    { path: '/cooperation', name: 'cooperation', component: () => import('../views/cooperation')},
    { path: '/lookHouse', name: 'lookHouse', component: () => import('../views/lookHouse')},
    { path: '/news', name: 'news', component: () => import('../views/news')},
    { path: '/newsDetail', name: 'newsDetail', component: () => import('../views/news/detail')},
    { path: '/aboutUs', name: 'aboutUs', component: () => import('../views/aboutUs')}
]
const mobileRoutes = [
    { path: '/', name: 'index', component: () => import('../views/mobile/index')},
    { path: '/brandDetail', name: 'brandDetail', component: () => import('../views/mobile/brand/brandDetail')},
    { path: '/cooperation', name: 'cooperation', component: () => import('../views/mobile/cooperation')},
    { path: '/lookHouse', name: 'lookHouse', component: () => import('../views/mobile/lookHouse')},
    { path: '/houseDetail', name: 'houseDetail', component: () => import('../views/mobile/lookHouse/houseDetail')},
    { path: '/news', name: 'news', component: () => import('../views/mobile/news')},
    { path: '/newsDetail', name: 'newsDetail', component: () => import('../views/mobile/news/detail')},
    { path: '/aboutUs', name: 'aboutUs', component: () => import('../views/mobile/aboutUs')}
]

const router = new VueRouter({
    mode: 'history',
    routes: isMobile()?mobileRoutes:routes
})


const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};

//路由守卫
router.beforeEach((to, from, next) => {
    // 路由跳转后，让页面回到顶部
    document.body.scrollTop = 0; 
    document.documentElement.scrollTop = 0; 
    window.pageYOffset = 0; 
    //next() 放行 next('/login') 强制跳转
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁!!
    next();
});
export default router
