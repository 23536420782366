import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import tools from './utils/tools'
import animate from 'animate.css'
import VueLazyload from "vue-lazyload";
import loading from "@/components/loading"
import mobileLoading from "@/views/mobile/components/loading"
import showToast from '@/views/mobile/components/message/index.js'

Vue.use(showToast);

Vue.use(VueLazyload,{
  preLoad: 1.3,
  loading: require('../src/assets/image/loading.gif'),
  attempt: 1
})
Vue.component('loading',loading)
Vue.component('mobileLoading',mobileLoading)
Vue.use(animate)
Vue.config.productionTip = false;
Vue.prototype.$bus = new Vue(); // event Bus 用于无关系组件间的通信。
Vue.prototype.$tools = tools;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
