<template>
  <div id="app">
    <headers v-if="isPc"/>
    <mobile-header v-else/>
    <!-- 路由占位符 -->
    <router-view />
    <footers v-if="isPc"/>
    <mobile-footer v-else/>
  </div>
</template>

<script>
import headers from '@/components/header';
import footers from '@/components/footer'
import mobileFooter from '@/views/mobile/footer'
import mobileHeader from '@/views/mobile/header'
import { isMobile } from '@/utils/ismobile.js'
export default {
  name: 'App',
  components: {
    headers,
    footers,
    mobileFooter,
    mobileHeader
  },
  data() {
    return {
      isPc: false
    }
  },
  mounted() {
    if(isMobile()) {
      this.isPc = false
    }else {
      this.isPc = true
    }
  },
}
</script>

<style>
@import "./common/css/common.scss";
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
</style>
