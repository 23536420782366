import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        distanceToBottom: 0
    },
    mutations: {
        setDistanceToBottom: (state, value) => {
            state.distanceToBottom = value
        }
    },
    actions: {
        
    },
    getters: {

    },
    modules: {

    }
})
