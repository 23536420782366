export default {
    // 前端静态删除
    /**
     * 
     * @param {需要删除更新的数组} mainList 
     * @param {需要删除在数组中的数据} needDelList 
     * @param {数组的要根据什么字段来删除} key 
     * @returns 
     */
    staticDelete(mainList, needDelList, key = 'id') {
        const _arr = mainList
        needDelList.forEach(_obj => {
            const length = _arr.length
            for (let i = 0; i < length; i++) {
                if (_arr[i][key] == _obj) {
                    if (i == 0) {
                        _arr.shift() // 删除并返回数组的第一个元素
                        return _arr
                    } else if (i == length - 1) {
                        _arr.pop() // 删除并返回数组的最后一个元素
                        return _arr
                    } else {
                        _arr.splice(i, 1) // 删除下标为i的元素
                        return _arr
                    }
                }
            }
        })
        return _arr
    },

    // 节流
    throttle(fn, interval) {
        let flag = true;
        return function (...args) {
            let context = this;
            if (!flag) return;
            flag = false;
            setTimeout(() => {
                fn.apply(context, args);
                flag = true;
            }, interval);
        };
    },

    // 防抖
    debounce(fn, delay) {
        let timer = null;
        return function (...args) {
            let context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(function () {
                fn.apply(context, args);
            }, delay);
        }
    },

    // 加强版节流
    // 把防抖和节流放到一起
    // 因为防抖有时候触发的太频繁会导致一次响应都没有，我们希望到了固定的时间必须给用户一个响应
    throttle_debounce(fn, delay) {
        let last = 0, timer = null;
        return function (...args) {
            let context = this;
            let now = new Date();
            if (now - last < delay) {
                clearTimeout(timer);
                setTimeout(function () {
                    last = now;
                    fn.apply(context, args);
                }, delay);
            } else {
                // 这个时候表示时间到了，必须给响应
                last = now;
                fn.apply(context, args);
            }
        }
    }
}